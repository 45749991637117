import React, { Fragment, useEffect, useState } from "react";
import { Icon } from "../Icon/Icon";
import { useDevice } from "../../hooks/useDevice";
import "./NavMenu.css";
import { useLocation } from "react-router-dom";

const NavMenu = () => {
  const [showNavBarModal, setNavBarModal] = useState(false);
  const { pathname } = useLocation();
  const device = useDevice();

  useEffect(() => {
    document.body.style.overflow =
      device === "mobile" && showNavBarModal ? "hidden" : "visible";
  }, [showNavBarModal, device]);

  const toggleNavBar = () => {
    setNavBarModal(prevValue => !prevValue);
  };

  const scrollToSection = sectionId => () => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setNavBarModal(false);
    }
  };

  const mobileNavViewClass =
    device === "mobile" && showNavBarModal ? "column-view" : "";

  const page = {
    default: (
      <Fragment>
        <li className="menu-cross mobile-only" onClick={toggleNavBar}>
          <Icon name="cross" />
        </li>
        <li
          className="nav-item mobile-only global-header-nav-link"
          onClick={toggleNavBar}
        >
          Home
        </li>
        <li className="nav-item" onClick={scrollToSection("about")}>
          About
        </li>
        <li className="nav-item" onClick={scrollToSection("services")}>
          Services
        </li>
        <li className="nav-item" onClick={scrollToSection("why-us")}>
          Why Us?
        </li>
        {/* <li className="nav-item" onClick={scrollToSection("team")}>Team</li> */}
        <li className="nav-item" onClick={scrollToSection("footer")}>
          Contact Us
        </li>
      </Fragment>
    ),
    "/careers": (
      <Fragment>
        <li className="menu-cross mobile-only" onClick={toggleNavBar}>
          <Icon name="cross" />
        </li>
        <li
          className="nav-item mobile-only global-header-nav-link"
          onClick={toggleNavBar}
        >
          Home
        </li>
        <li className="nav-item" onClick={scrollToSection("about")}>
          About
        </li>
        <li className="nav-item" onClick={scrollToSection("benefits")}>
          Benefits
        </li>
        <li className="nav-item" onClick={scrollToSection("openings")}>
          Openings
        </li>
        {/* <li className="nav-item" onClick={scrollToSection("team")}>Team</li> */}
        <li className="nav-item" onClick={scrollToSection("footer")}>
          Contact Us
        </li>
      </Fragment>
    )
  };

  return (
    <Fragment>
      <button className="menu-hamburger mobile-only" onClick={toggleNavBar}>
        <Icon name="hamburger" />
      </button>
      <ul className={`nav-list ${mobileNavViewClass}`}>
        {page[pathname] || page.default}
      </ul>
    </Fragment>
  );
};

export { NavMenu };
