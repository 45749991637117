import React, { useState } from "react";
import { Flip } from "./Flip";

const FlipGroup = ({ cards, startAnimation }) => {
  const [activeIndex, setIndex] = useState(0);
  const handleAnimationEnd = () => {
    const current = cards.length === activeIndex + 1 ? 0 : activeIndex + 1;
    setIndex(current);
  };

  const updateIndex = index => () => {
    setIndex(index);
  };

  return cards.map((eachCard, index) => (
    <Flip
      {...eachCard}
      key={index}
      onMouseEnter={updateIndex(index)}
      animate={startAnimation ? index === activeIndex : false}
      onAnimationEnd={handleAnimationEnd}
    />
  ));
};

export { FlipGroup };
