import React from "react";
import { Button } from "../Button/Button";
import "./JoinUs.css";
import { useNavigate } from "react-router-dom";

const JoinUs = () => {
  const navigate = useNavigate();
  const navigateToCulture = () => {
    navigate("/", { state: { targetSection: "values" } });
  };
  const navigateToJobs = () => {
    const section = document.getElementById("openings");
    section.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="joinus-container" id="about">
      <h1 className="joinus-title">Join us</h1>
      <p className="joinus-description">
        In publishing and graphic design, Lorem ipsum is a placeholder text
        commonly used to demonstrate the visual form of a document or a typeface
        without relying on meaningful content. Lorem ipsum may be used as a
        placeholder before final copy is available
      </p>
      <div className="joinus_button-wrapper">
        <Button className="joinus-btn" onClick={navigateToJobs}>
          Join the team
        </Button>

        <Button
          className="joinus-btn"
          variant="outline"
          onClick={navigateToCulture}
        >
          Culture
        </Button>
      </div>
    </div>
  );
};

export { JoinUs };
