import React, { Fragment } from "react";
import { Layout, Left, Right } from "../Layout/Layout";
import male from "../../assets/images/male.png";
import female from "../../assets/images/female.png";
import "./Benefit.css";
import { useAnimateOnScroll } from "../../hooks/useAnimateOnScroll";

const benefits = [
  {
    heading: (
      <Fragment>
        Dynamic, Trusted,{" "}
        <em className="benefit-text-highlight">Innovative </em>
        Solutions
      </Fragment>
    ),
    description: (
      <Fragment>
        AndinoLabs is your dynamic, trusted, and innovative solutions partner,
        offering expertise in Digital Commerce, Data Analytics, and Supply Chain
        Logistics. We excel in Evolutionary Architecture, Microservices, Legacy
        Modernization, Cloud Migrations, and App Development.
      </Fragment>
    ),
    image: <img src={male} className="benefit-image-male" alt="reliable" />
  },
  {
    heading: (
      <Fragment>
        <em className="benefit-text-highlight">Transformative</em> Solutions for
        Tomorrow
      </Fragment>
    ),
    description: (
      <Fragment>
        With a multilingual team and a passion for nature, we deliver
        transformative solutions that bring joy to your business and drive
        digital transformation. Choose AndinoLabs for reliable, impactful, and
        customer-centric solutions.
      </Fragment>
    ),
    image: <img src={female} className="benefit-image-female" alt="reliable" />
  }
];

const Benefit = () => {
  const ref0 = useAnimateOnScroll();
  const ref1 = useAnimateOnScroll();

  const refs = [ref0, ref1];
  const animations = ["swipe-from-left", "swipe-from-right"];
  return (
    <div className="benefits-container">
      {benefits.map(({ heading, description, image }, index) => (
        <Layout className="benefit-section" ref={refs[index][0]} key={index}>
          <Left>
            <section className={`benefit-content-${index}`}>
              <h2 className="benefit-title">{heading}</h2>
              <p className="benefit-para">{description}</p>
            </section>
          </Left>
          <Right>
            <div
              className={`${refs[index][1] ? animations[index] : "invisible"}`}
            >
              {image}
            </div>
          </Right>
        </Layout>
      ))}
    </div>
  );
};

export { Benefit };
