import React from "react";
import logo from "../../assets/images/logo_original.png";
import { Icon } from "../Icon/Icon";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./Contact.css";

const Contact = () => {
  const { pathname } = useLocation();
  const navigateTo = sectionId => () => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCareers = () => {
    if (pathname === "/careers") {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="contact-container">
      <img src={logo} className="footer-logo" alt="andino-labs-logo" />
      <address className="address">
        312-313, Symphony Managed office space, No 23, 187/1260/28/2/23,
        <br />
        Third floor, Hennur Main Road (New Airport Road) Bengaluru- 560043
      </address>
      <nav className="footer-nav">
        <h4 className="footer-heading">NAVIGATION</h4>
        <ul className="footer-links">
          {/* <li className="footer-nav-item">Get Started</li> */}
          <li className="footer-nav-item" onClick={navigateTo("about")}>
            About
          </li>
          {/* <li className="footer-nav-item">Resources</li> */}

          <li className="footer-nav-item">
            <Link
              to="https://www.blogger.com/u/1/blog/posts/5566326138091978483?hl=en"
              target="_blank"
              className="external-link"
            >
              Blog
            </Link>
          </li>
          <NavLink
            to="/careers"
            className="footer-nav-link"
            onClick={handleCareers}
          >
            <li className="footer-nav-item">Careers</li>
          </NavLink>
        </ul>
      </nav>
      <div className="media">
        <h4 className="footer-heading">SOCIAL MEDIA</h4>
        <div className="media-links">
          <a
            href="https://www.linkedin.com/company/99306609/admin/feed/posts/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="linkedIn" />
          </a>
          <a
            href="https://twitter.com/AndinoLabs/"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="twitter" />
          </a>
        </div>
      </div>
      <small className="copy-rights">
        &copy; Copyright 2024. All rights reserved. Privacy policy.
      </small>
    </div>
  );
};

export { Contact };
