import React from "react";
import { NavMenu } from "../NavMenu/NavMenu";
import logo from "../../assets/images/logo_original.png";
// import logo from "../../assets/images/new_logo.png";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ className = "" }) => {
  return (
    <nav className={`nav-bar ${className}`}>
      <NavLink to="/">
        <img src={logo} className="logo" alt="andino-labs-logo" />
      </NavLink>
      <NavMenu />
    </nav>
  );
};

export { Navbar };
