import React from "react";
import "./Flip.css";

const Flip = ({
  front,
  back,
  animate,
  onAnimationEnd,
  onMouseEnter
}) => {
  return (
    <div className="flip-card" onMouseEnter={onMouseEnter}>
      <div
        className={`flip-card-inner ${animate ? "animate" : ""}`}
        onAnimationEnd={onAnimationEnd}
      >
        <div className="flip-card-front">{front}</div>
        <div className="flip-card-back">{back}</div>
      </div>
    </div>
  );
};

export { Flip };
