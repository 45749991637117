import React, { Fragment } from "react";
import { Layout, Left, Right } from "../Layout/Layout";
import Joinus_Benfits_image_1 from "../../assets/images/Joinus_Benefits_image_1.png";
import Joinus_Benfits_image_2 from "../../assets/images/Joinus_Benfits_image_2.png";
import Joinus_Benfits_image_3 from "../../assets/images/Joinus_Benfits_image_3.png";
import Joinus_Benfits_image_4 from "../../assets/images/Joinus_Benfits_image_4.png";
import "./Joinusbenefits.css";
import { useAnimateOnScroll } from "../../hooks/useAnimateOnScroll";

const joinbenefits = [
  {
    joinLogo: (
      <img
        src={Joinus_Benfits_image_1}
        className="joinus_benefits_image_1"
        width="38px"
        height="38px"
        alt="joinus_benefits_image_1"
      />
    ),
    joinTitle: <Fragment>Team work</Fragment>,
    joinDescription: (
      <Fragment>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry.
      </Fragment>
    )
  },
  {
    joinLogo: (
      <img
        src={Joinus_Benfits_image_2}
        className="joinus_benefits_image_2"
        width="38px"
        height="38px"
        alt="joinus_benefits_image_2"
      />
    ),
    joinTitle: <Fragment>Secured Future </Fragment>,
    joinDescription: (
      <Fragment>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry.
      </Fragment>
    )
  },
  {
    joinLogo: (
      <img
        src={Joinus_Benfits_image_3}
        className="joinus_benefits_image_3"
        width="58px"
        height="46px"
        alt="joinus_benefits_image_3"
      />
    ),
    joinTitle: <Fragment>Learning Opportunity</Fragment>,
    joinDescription: (
      <Fragment>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry.
      </Fragment>
    )
  },
  {
    joinLogo: (
      <img
        src={Joinus_Benfits_image_4}
        className="joinus_benefits_image_4"
        width="38px"
        height="35.29px"
        alt="joinus_benefits_image_4"
      />
    ),
    joinTitle: <Fragment>Upgrate Skills</Fragment>,
    joinDescription: (
      <Fragment>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry.
      </Fragment>
    )
  }
];

const Joinusbenefits = () => {
  const [ref, isVisible] = useAnimateOnScroll();
  return (
    <Layout className="joinusBenefits-container" id="benefits" ref={ref}>
      <Left>
        <div
          className={`joinusBenefits-section ${
            isVisible ? "swipe-from-left" : "invisible"
          }`}
        >
          <h3 className="joinusBenefits-title">BENEFITS</h3>
          <h2 className="joinusBenefits-subtitle">
            Why you Should Join Our Awesome Team
          </h2>
          <p className="joinusBenefits-description">
            we want to feel like home when you are working at JMC(Japan
            Marketing & Consultancy Limited) & for that we have curated a great
            set of benefits for you.It all starts with the free lunch!
          </p>
        </div>
      </Left>

      <Right>
        <div
          className={`joinusBenefits-rightContainer ${
            isVisible ? "swipe-from-right" : "invisible"
          }`}
        >
          {joinbenefits.map(
            ({ joinLogo, joinTitle, joinDescription }, index) => (
              <div key={index} className="joinus-benfits-item">
                <div className="joinusBenefits-image-background">
                  {joinLogo}
                </div>
                <h4 className="joinus-benefit-title">{joinTitle}</h4>
                <p className="joinusBenefits-description-right">
                  {" "}
                  {joinDescription}
                </p>
              </div>
            )
          )}
        </div>
      </Right>
    </Layout>
  );
};

export { Joinusbenefits };
